import { driversAtom } from "@/atoms/driversAtom"
import KosmoButton from "@/UI/Buttons/KosmoButton"
import DateFilter from "@/UI/Filters/DateFilter"
import { DateFilterType, LUXON_PICKER_DATE_FORMAT } from "@/utils/datetime"
import { PlusOutlined } from "@ant-design/icons"
import { Form, Input, Modal, Upload } from "antd"
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload"
import { isEmpty } from "lodash"
import { DateTime } from "luxon"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSetRecoilState } from "recoil"

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

interface FakeDocumentUploadProps {
  driverId: string
  button: React.ReactElement
}

const FakeDocumentUpload: React.FC<FakeDocumentUploadProps> = ({ driverId, button }) => {
  const { t } = useTranslation(["common"])
  const setDrivers = useSetRecoilState(driversAtom)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState("")
  const [previewTitle, setPreviewTitle] = useState("")
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [fileName, setFileName] = useState<string>()
  const [dates, setDates] = useState<DateFilterType>({
    range: [
      DateTime.now().startOf("day").toFormat(LUXON_PICKER_DATE_FORMAT),
      DateTime.now().startOf("day").toFormat(LUXON_PICKER_DATE_FORMAT),
    ],
    format: LUXON_PICKER_DATE_FORMAT,
  })
  const [form] = Form.useForm()
  useEffect(() => {
    return () => {
      setFileList([])
      setPreviewImage("")
      setPreviewTitle("")
      setPreviewOpen(false)
    }
  }, [])

  useEffect(() => {
    setFileList([])
    setFileName("")
    form.resetFields()
  }, [isModalVisible])

  const handleCancel = () => setPreviewOpen(false)
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1))
  }

  const onOk = () => {
    setDrivers((driversState) => {
      const driverToUpdate = driversState.find((driverFromState) => driverFromState.id === driverId)
      if (driverToUpdate && driverToUpdate !== undefined && dates?.range?.[1]) {
        return [
          ...driversState?.filter((driverFromState) => driverFromState.id !== driverId),
          {
            ...driverToUpdate,
            attachments: [
              ...(driverToUpdate?.attachments || []),
              {
                name: fileName!,
                url: isEmpty(driverToUpdate?.attachments) ? urls[0]! : urls[1]!,
                expiresAt: DateTime.fromFormat(dates?.range?.[1], dates?.format)?.toMillis(),
              },
            ],
          },
        ]
      }
      return driversState
    })
  }

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList)
  }

  const urls = [
    "https://img.freepik.com/premium-vector/white-passport-black-background-web-design_651849-216.jpg",
    "https://t4.ftcdn.net/jpg/02/52/61/99/360_F_252619938_7gWKbyhhMKqQxZxewd4eZKAJsvF5ellc.jpg",
  ]

  return (
    <>
      {React.cloneElement(button, { onClick: () => setIsModalVisible(true) })}
      <Modal
        open={isModalVisible}
        footer={null}
        closable={true}
        onCancel={() => setIsModalVisible(false)}
        destroyOnClose={true}
        zIndex={3000}
        styles={{ body: { paddingTop: "0px" } }}
        title={t("common.uploadDocument")}
      >
        <div className="w-full flex ant-upload-drag-driver gap-y-2 flex-col">
          <Form form={form} layout="vertical">
            <Form.Item label={t("common.documentName")} name="fileName">
              <Input
                value={fileName}
                prefix=" "
                name="fileName"
                placeholder={t("common.passport")}
                onChange={(e) => setFileName(e.currentTarget.value)}
              />
            </Form.Item>
            <Form.Item label={t("common.expiresAt")} name="fileName">
              <DateFilter
                date={dates.range[1]}
                setDate={(date: string) => {
                  setDates((datesState) => ({ ...datesState, range: [date, date] }))
                }}
              />
            </Form.Item>
            <Form.Item label={t("common.file")}>
              <Upload
                accept={"image/png, image/jpeg"}
                className="flex"
                listType="picture-card"
                style={{ width: 35, height: 35 }}
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                maxCount={1}
              >
                <PlusOutlined />
              </Upload>
            </Form.Item>
          </Form>
        </div>
        <div className="flex w-full justify-end gap-x-4 mt-4">
          <KosmoButton type="default" onClick={() => setIsModalVisible(false)}>
            {t("common.cancel")}
          </KosmoButton>
          <KosmoButton
            type="primary"
            onClick={() => {
              onOk()
              setIsModalVisible(false)
            }}
            htmlType="submit"
            isDisabled={fileList?.length === 0 || !fileName}
          >
            {t("common.save")}
          </KosmoButton>
        </div>
      </Modal>
      <Modal
        zIndex={9000}
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  )
}

export default FakeDocumentUpload
